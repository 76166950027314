import { Stack } from '@mui/system';
import { compareAsc } from 'date-fns';
import { Visit } from 'kurt/pages/Movements/useVisits';
import { Ref } from 'react';
import { useNavigate } from 'react-router-dom';
import { MovementRequestCard } from './MovementRequestCard';

export type MovementRequestProps = {
  visits: Visit[];
  containerRef: Ref<HTMLDivElement>;
  isReadOnly?: boolean;
};

export const MovementRequestList = ({ visits, containerRef, isReadOnly }: MovementRequestProps) => {
  const navigate = useNavigate();

  const onCardClick = (movementId: string) => {
    navigate('mutation/' + movementId);
  };

  const filteredVisits = visits.filter((visit) => !!visit.basedOn);
  const sortedVisits = filteredVisits.sort((a, b) => compareAsc(a.basedOn!.createdAt, b.basedOn!.createdAt));

  return (
    <Stack spacing={2} padding="16px 24px" ref={containerRef}>
      {sortedVisits.map((visit) => (
        <MovementRequestCard isReadOnly={isReadOnly} visit={visit} onClick={onCardClick} key={visit.id} />
      ))}
    </Stack>
  );
};
