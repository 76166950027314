import { SvgIcon, SvgIconProps } from '@mui/material';

const FullChevronRightIcon: React.FC<SvgIconProps> = ({ ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46971 1.46967C4.17681 1.76256 4.17681 2.23744 4.46971 2.53033L9.93938 8L4.46971 13.4697C4.17681 13.7626 4.17681 14.2374 4.46971 14.5303C4.7626 14.8232 5.23747 14.8232 5.53037 14.5303L11.3536 8.70711C11.7441 8.31658 11.7441 7.68342 11.3536 7.29289L5.53037 1.46967C5.23747 1.17678 4.7626 1.17678 4.46971 1.46967Z"
    />
  </SvgIcon>
);

export default FullChevronRightIcon;
