import { DateTimePickerProps } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { Calendar, ChevronDown } from 'common/components/Icons';
import FullChevronLeftIcon from 'common/components/Icons/FullChevronLeftIcon';
import FullChevronRightIcon from 'common/components/Icons/FullChevronRightIcon';
import { i18n } from 'common/locale';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import palette from '../palette';

const MuiDateTimePicker: {
  defaultProps: DateTimePickerProps<Date>;
} = {
  defaultProps: {
    format: i18n.dateTimeFormatCompact,
    dayOfWeekFormatter: (date: Date) => {
      const day = format(date, 'eeeeee', { locale: fr });
      return day[0].toUpperCase() + day[1];
    },
    slots: {
      actionBar: (props: any) => (
        <Stack position="absolute" bottom={0}>
          <button
            onClick={props.onSetToday}
            style={{ border: 'none', backgroundColor: 'transparent', marginBottom: 32 }}
          >
            <Typography color="primary" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              {i18n.clear}
            </Typography>
          </button>
        </Stack>
      ),
      leftArrowIcon: FullChevronLeftIcon,
      rightArrowIcon: FullChevronRightIcon,
      switchViewIcon: ChevronDown,
      openPickerIcon: Calendar,
    },
    slotProps: {
      views: ['month', 'day', 'hours', 'minutes'],
      textField: {
        sx: {
          maxWidth: 145,
        },
      },
      popper: { sx: { '& .MuiPickersPopper-paper': { marginTop: 2 } } },
      layout: {
        sx: {
          padding: 8,
          '& hr': { display: 'none' },
          '& .MuiPickersLayout-contentWrapper': { display: 'flex !important', flexDirection: 'row', gap: 8 },
          '& .MuiDateCalendar-root': {
            width: 354,
            display: 'flex',
            gap: 6,
          },
          '& .MuiPickersArrowSwitcher-spacer': {
            width: 12,
          },
          '& .MuiPickersArrowSwitcher-button': {
            width: 20,
            height: 20,
            margin: 0,
          },
          '& .MuiPickersCalendarHeader-root': {
            minHeight: 20,
          },
          '& .MuiPickersCalendarHeader-label': {
            fontSize: 16,
          },
          '& .MuiDayCalendar-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          },
          '& .MuiDayCalendar-header': {
            display: 'flex',
            gap: 3,
          },
          '& .MuiDayCalendar-weekDayLabel': {
            width: 36,
            height: 15,
            fontSize: 14,
            color: 'black',
          },
          '& .MuiDayCalendar-monthContainer': {
            display: 'flex',
            flexDirection: 'column',
          },
          '& .MuiDayCalendar-weekContainer': {
            gap: 3,
          },
          '& .MuiPickersDay-dayWithMargin': {
            fontSize: 14,
            fontWeight: 400,
            color: palette.secondary.main,
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: palette.primary.main,
            },
            '&:hover': {
              backgroundColor: palette.primary.medium,
            },
          },
          '& .MuiMultiSectionDigitalClock-root': {
            width: 'fit-content',
            paddingX: 2,
            paddingY: 6,
            borderRadius: 1,
            backgroundColor: palette.background.default,
          },
          '& .MuiMultiSectionDigitalClockSection-item': {
            borderRadius: 1 / 2,
            fontWeight: 400,
            color: palette.secondary.main,
            '&:hover': {
              backgroundColor: palette.primary.medium,
              color: palette.secondary.main,
            },
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: palette.primary.main,
              '&:focus': {
                backgroundColor: palette.primary.main,
              },
            },
          },
        },
      },
      calendarHeader: { sx: { padding: 0, margin: 0, height: 20 } },
    },
  },
};

export default MuiDateTimePicker;
