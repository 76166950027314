import { SvgIcon, SvgIconProps } from '@mui/material';

const ChevronDown: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5303 4.46971C14.2374 4.17681 13.7626 4.17681 13.4697 4.46971L8 9.93938L2.53033 4.46971C2.23744 4.17681 1.76256 4.17681 1.46967 4.46971C1.17678 4.7626 1.17678 5.23747 1.46967 5.53037L7.29289 11.3536C7.68342 11.7441 8.31658 11.7441 8.70711 11.3536L14.5303 5.53037C14.8232 5.23747 14.8232 4.7626 14.5303 4.46971Z"
      />
    </SvgIcon>
  );
};

export default ChevronDown;
