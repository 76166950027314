import Api, { Practitioner, Unit } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';
import { useMemo } from 'react';

export type PractitionerQueryParams = {
  unitIds: string[];
  rowsPerPage?: number;
  page?: number;
};

type UsePractitionersParams = PractitionerQueryParams & { enabled?: boolean };

const usePaginatedPractitioners = ({ unitIds, page, rowsPerPage, enabled = false }: UsePractitionersParams) => {
  const {
    results,
    isLoading: isPractitionersQueryLoading,
    refetch,
  } = useParseQuery(getPractitionersQuery({ unitIds, rowsPerPage, page }), {
    enabled,
  });

  const { count, isLoading: isCountQueryLoading } = useParseQueryCount(getPractitionersQuery({ unitIds }));

  const practitioners = useMemo(() => results.sort((a, b) => a.lastName.localeCompare(b.lastName)), [results]);

  return { practitioners, count, loading: isPractitionersQueryLoading || isCountQueryLoading, refetch };
};

const getPractitionersQuery = ({ unitIds, rowsPerPage, page }: PractitionerQueryParams) => {
  const query = new Api.Query(Practitioner).containedIn(
    'unit',
    unitIds.map((id) => Unit.createWithoutData(id))
  );

  if (page) {
    query.skip(page * (rowsPerPage || 30));
  }

  return query;
};

export default usePaginatedPractitioners;
