import { Cloud, Unit } from '@ambuliz/sabri-core';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { DialogHeader } from 'common/components';
import useParseResource from 'common/hooks/useParseResource';
import { toast } from 'common/toast';
import { useMyHealthCenter } from 'core/healthcenter';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UnitForm, { UnitFormState } from '../components/UnitForm';

type UnitDetailsDialogFormProps = {
  unit: UnitFormState;
  enableAutoComputeDischarge: boolean;
  onSubmit: (unit: UnitFormState) => void;
  onClose: () => void;
};

export const UnitDetailsLoading = () => {
  return (
    <Stack m={4} alignItems="center">
      <CircularProgress />
    </Stack>
  );
};

export const UnitDetailsDialogForm = ({
  unit: initialUnit,
  onSubmit,
  onClose,
  enableAutoComputeDischarge,
}: UnitDetailsDialogFormProps) => {
  const [unit, setUnit] = useState<UnitFormState>(initialUnit);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(unit);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogHeader title="Modifier une unité" onClose={onClose} />
      <DialogContent>
        <UnitForm onChange={setUnit} unit={unit} enableAutoComputeDischarge={enableAutoComputeDischarge} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Annuler
        </Button>
        <Button type="submit">Modifier</Button>
      </DialogActions>
    </form>
  );
};

export const UnitDetailsDialog = () => {
  const { myHealthCenter } = useMyHealthCenter();
  const [open, setOpen] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { unitId } = useParams();
  const { resource, isLoading } = useParseResource(unitId as string, Unit);

  const navigate = useNavigate();

  const handleClose = (refresh = false) => {
    setOpen(false);
    setShouldRefresh(refresh);
  };
  const handleRedirect = () =>
    navigate('..', {
      state: {
        refresh: shouldRefresh,
      },
    });

  const handleSubmit = async (unit: UnitFormState) => {
    if (!unitId) {
      return;
    }

    try {
      await Cloud.updateUnit({
        healthCenterId: myHealthCenter?.id || '',
        id: unitId,
        name: unit.name,
        type: unit.type,
        areaIds: unit.areas || [],
        specialties: unit.specialties || [],
        externalId: unit.externalId,
        autoComputeDischarge: unit.autoComputeDischarge,
        autoComputeOccupanciesActivated: unit.autoComputeOccupanciesActivated,
        contacts: unit.contacts || [],
        department: unit.department,
        isEmergency: unit.isEmergency,
        requestFareConfirmation: unit.requestFareConfirmation,
        responsibleUnits: unit.responsibleUnits || [],
      });

      toast.success(`L'unité ${unit.name} a été modifiée.`);
      handleClose(true);
    } catch (error) {
      switch (error.code) {
        case 'VALIDATION_FAILED':
          toast.error(
            `${Object.values(error.errors)
              ?.map((error: any) => error.message)
              .join(', ')}`
          );
          break;
        case 'SCRIPT_FAILED':
          toast.error(error.message);
          break;
        default:
          toast.error('Une erreur est survenue');
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} onTransitionExited={handleRedirect} maxWidth="lg" scroll="body">
      {isLoading || !resource ? (
        <UnitDetailsLoading />
      ) : (
        <UnitDetailsDialogForm
          unit={{
            name: resource.name,
            type: resource.type,
            specialties: resource.specialties || [],
            areas: resource.areas?.map(({ id }) => id) || [],
            externalId: resource.externalId || '',
            responsibleUnits: resource.responsibleUnits || [],
            autoComputeDischarge: resource.autoComputeDischarge || false,
            autoComputeOccupanciesActivated: resource.autoComputeOccupanciesActivated || false,
            contacts: resource.contacts || [],
            department: resource.department || '',
            isEmergency: resource.isEmergency || false,
            requestFareConfirmation: resource.requestFareConfirmation || false,
          }}
          enableAutoComputeDischarge={!!myHealthCenter?.autoComputeDischarge}
          onSubmit={handleSubmit}
          onClose={() => handleClose()}
        />
      )}
    </Dialog>
  );
};
