import { Stack, Typography } from '@mui/material';
import { BellOutlinedIcon } from 'common/components/Icons/BellOutlinedIcon';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import shadows from 'common/theme/shadows';
import { filterBySearch } from 'common/utils';
import { format } from 'date-fns';
import useVisits from 'kurt/pages/Movements/useVisits';
import { useCallback, useMemo, useRef } from 'react';
import { MovementRequestList } from './MovementRequestList';

const PATIENT_PLACEMENT_TOP_HEIGHT = 118;

export type MovementRequestContainerProps = {
  unitIds: string[];
  isReadOnly: boolean;
  search?: string;
  onHeaderClick: () => void;
  setMovementRequestListHeight: (height: number) => void;
};

export const MovementRequestContainer = ({
  unitIds,
  isReadOnly,
  search,
  setMovementRequestListHeight,
  onHeaderClick,
}: MovementRequestContainerProps) => {
  const now = useRef(new Date());
  const {
    visits: { requested },
  } = useVisits('admission', format(now.current, 'yyyy-MM-dd'), unitIds[0], () => {});

  const filteredRequests = useMemo(() => {
    if (!search) {
      return requested;
    }
    return filterBySearch({ search: search, list: requested, keys: ['patient.fullName'], minScore: 0.5 });
  }, [search, requested]);

  const handleListHeight = useCallback(
    (node: HTMLDivElement) => setMovementRequestListHeight(node?.clientHeight || 0),
    [setMovementRequestListHeight]
  );

  return (
    <>
      <Stack
        paddingX={6}
        paddingY={4}
        position="sticky"
        top={PATIENT_PLACEMENT_TOP_HEIGHT}
        bgcolor="white"
        zIndex={2}
        boxShadow={shadows[5]}
        onClick={onHeaderClick}
        sx={{ cursor: 'pointer' }}
      >
        <Stack direction="row">
          <BellOutlinedIcon />
          <Typography marginLeft={2} fontSize={16} fontWeight={600}>
            {i18n.movementRequests}
          </Typography>
          <Typography fontSize={16} fontWeight={600} color={palette.secondary.main}>
            &nbsp;({filteredRequests.length})
          </Typography>
        </Stack>
      </Stack>
      {filteredRequests.length > 0 && (
        <MovementRequestList visits={filteredRequests} containerRef={handleListHeight} isReadOnly={isReadOnly} />
      )}
    </>
  );
};
