import { Drawer, IconButton, Stack, TextField, Typography } from '@mui/material';
import CrossIcon from 'common/components/Icons/Cross';
import SearchIcon from 'common/components/Icons/Search';
import { useDebounce } from 'common/hooks';
import { i18n } from 'common/locale';
import { theme } from 'common/theme';
import { useAppBarContext } from 'core/layout';
import { useCallback, useRef, useState } from 'react';
import PatientPlacementContainer from './PatientPlacementContainer';
import { MovementRequestContainer } from './movementRequests/MovementRequestsContainer';

export const PATIENT_PLACEMENT_SIDEBAR_WIDTH = 372;

type PatientPlacementSidebarProps = {
  unitIds: string[];
  isReadOnly?: boolean;
  open: boolean;
  isActionInProgress?: boolean;
  onClose: () => void;
};

const PatientPlacementSidebar = ({
  unitIds,
  isReadOnly = false,
  open,
  isActionInProgress = false,
  onClose,
}: PatientPlacementSidebarProps) => {
  const [enabled, setEnabled] = useState(false);
  const { appBarHeight } = useAppBarContext();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);

  const containerRef = useRef<HTMLDivElement>(null);
  const [movementRequestListHeight, setMovementRequestListHeight] = useState(0);

  const scrollToMovementRequests = useCallback(() => {
    containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [containerRef]);

  const scrollToPatientsToBePlaced = useCallback(() => {
    containerRef?.current?.scrollTo({ top: movementRequestListHeight, behavior: 'smooth' });
  }, [containerRef, movementRequestListHeight]);

  return (
    <Drawer
      open={open}
      variant="persistent"
      anchor="right"
      onClose={onClose}
      onTransitionEnd={() => setEnabled(open)}
      hideBackdrop
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          marginTop: `${appBarHeight - 1}px`,
          width: PATIENT_PLACEMENT_SIDEBAR_WIDTH,
          boxSizing: 'border-box',
          height: `calc(100% - ${appBarHeight}px)`,
        },
        zIndex: theme.zIndex.appBar - 1,
      }}
    >
      <Stack ref={containerRef} overflow="auto">
        <SidebarTop search={search} setSearch={setSearch} onClose={onClose} />
        {unitIds.length > 0 && (
          <MovementRequestContainer
            unitIds={unitIds}
            isReadOnly={isReadOnly}
            search={debouncedSearch}
            onHeaderClick={scrollToMovementRequests}
            setMovementRequestListHeight={setMovementRequestListHeight}
          />
        )}
        <PatientPlacementContainer
          unitIds={unitIds}
          enabled={enabled}
          isReadOnly={isReadOnly}
          isActionInProgress={isActionInProgress}
          search={debouncedSearch}
          onHeaderClick={scrollToPatientsToBePlaced}
        />
      </Stack>
    </Drawer>
  );
};

const SidebarTop = ({
  search,
  setSearch,
  onClose,
}: {
  search: string;
  setSearch: (search: string) => void;
  onClose: () => void;
}) => (
  <Stack padding={6} paddingBottom={4} gap={4} position="sticky" top={0} zIndex={3} bgcolor="white">
    <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
      <Typography variant="h2">{i18n.myAdmissions}</Typography>
      {onClose && (
        <IconButton edge="end" onClick={onClose}>
          <CrossIcon />
        </IconButton>
      )}
    </Stack>
    <TextField
      placeholder={i18n.searchPatient}
      fullWidth
      variant="filled"
      size="small"
      value={search}
      InputProps={{ startAdornment: <SearchIcon /> }}
      onChange={(event) => setSearch(event.target.value)}
    />
  </Stack>
);

export default PatientPlacementSidebar;
