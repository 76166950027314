import { Practitioner } from '@ambuliz/sabri-core';
import { Autocomplete, Chip, Popper, Stack, TextField } from '@mui/material';
import CrossSmallIcon from 'common/components/Icons/CrossSmall';
import { i18n } from 'common/locale';
import { formatPractitionerName } from 'common/utils';
import usePractitioners from './usePractitioners';

type PractitionersAutocompleteProps = {
  value: Practitioner[];
  unitIds: string[];
  onChange: (value: Practitioner[]) => void;
};

const PractitionersAutocomplete = ({ value, onChange, unitIds }: PractitionersAutocompleteProps) => {
  const { practitioners, loading } = usePractitioners({ unitIds, enabled: true });

  const handleChange = (practitioners: Practitioner[]) => {
    onChange(practitioners);
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      value={value}
      options={practitioners}
      size="small"
      onChange={(_, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField {...params} placeholder={i18n.practitionersAutocomplete.placeholder} variant="filled" />
      )}
      PopperComponent={(props) => <Popper {...props} placement="top" />}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Stack direction="column" spacing={loading ? 2 : 0}>
              <Chip
                key={option.id}
                label={formatPractitionerName(option.firstName, option.lastName)}
                sx={{ backgroundColor: option.color }}
              />
            </Stack>
          </li>
        );
      }}
      clearIcon={<CrossSmallIcon />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={formatPractitionerName(option.firstName, option.lastName)}
            sx={{ backgroundColor: option.color }}
            {...getTagProps({ index })}
          />
        ))
      }
      getOptionLabel={(option) => formatPractitionerName(option.firstName, option.lastName)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default PractitionersAutocomplete;
