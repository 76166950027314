import PatientPlacementComponent from './PatientPlacementComponent';
import usePatientPlacement from './usePatientPlacement';

type PatientPlacementContainerProps = {
  unitIds: string[];
  enabled: boolean;
  isReadOnly?: boolean;
  isActionInProgress?: boolean;
  search?: string;
  onHeaderClick: () => void;
};

const PatientPlacementContainer: React.FC<PatientPlacementContainerProps> = ({
  unitIds,
  enabled,
  isReadOnly = false,
  isActionInProgress = false,
  search,
  onHeaderClick,
}) => {
  const { dateRange, onDateRangeChange, loading, patientsToBePlaced } = usePatientPlacement({ unitIds, enabled });

  return (
    <PatientPlacementComponent
      loading={loading}
      patientsToBePlaced={patientsToBePlaced}
      onDateRangeChange={onDateRangeChange}
      dateRange={dateRange}
      isReadOnly={isReadOnly}
      isActionInProgress={isActionInProgress}
      search={search}
      onHeaderClick={onHeaderClick}
    />
  );
};

export default PatientPlacementContainer;
