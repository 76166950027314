import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckOutline: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M6.29361 10.7733L2.27944 6.95128C2.13888 6.81745 1.94825 6.74227 1.74948 6.74227C1.5507 6.74227 1.36007 6.81745 1.21952 6.95128C1.07896 7.08511 0.999999 7.26661 0.999999 7.45587C0.999999 7.54958 1.01938 7.64238 1.05705 7.72895C1.09471 7.81553 1.14992 7.8942 1.21952 7.96046L5.76741 12.2906C6.06058 12.5698 6.53416 12.5698 6.82733 12.2906L14.7805 4.71819C14.921 4.58437 15 4.40286 15 4.2136C15 4.02434 14.921 3.84284 14.7805 3.70901C14.6399 3.57518 14.4493 3.5 14.2505 3.5C14.0518 3.5 13.8611 3.57518 13.7206 3.70901L6.29361 10.7733Z" />
    </SvgIcon>
  );
};

export default CheckOutline;
