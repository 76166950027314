import { useDroppable } from '@dnd-kit/core';
import { IconButton, Link, Popover, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DateCalendar } from '@mui/x-date-pickers';
import ArrowLeftIcon from 'common/components/Icons/ArrowLeft';
import ArrowRightIcon from 'common/components/Icons/ArrowRight';
import CalendarIcon from 'common/components/Icons/Calendar';
import NoBedIcon from 'common/components/Icons/NoBedIcon';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import shadows from 'common/theme/shadows';
import { filterBySearch } from 'common/utils';
import { addDays, endOfDay, format, isThisYear, isToday, isTomorrow, isValid, startOfDay, subDays } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PatientPlacementList from './PatientPlacementList';
import { DateRange, PatientToBePlaced } from './usePatientPlacement';

const PATIENT_PLACEMENT_TOP_PLUS_MOVEMENT_REQUEST_CONTAINER_TOP_HEIGHT = 168;

type PatientPlacementComponentProps = {
  loading: boolean;
  patientsToBePlaced: PatientToBePlaced[];
  isReadOnly?: boolean;
  dateRange: DateRange;
  isActionInProgress?: boolean;
  onDateRangeChange: (dateRange: DateRange) => void;
  search?: string;
  onHeaderClick: () => void;
};

const PatientPlacementComponent = ({
  loading,
  patientsToBePlaced,
  isReadOnly = false,
  dateRange,
  isActionInProgress = false,
  onDateRangeChange,
  search,
  onHeaderClick,
}: PatientPlacementComponentProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [hiddenCardId, setHiddentCardId] = useState<string | undefined>();

  const { setNodeRef, over, active } = useDroppable({
    id: 'patientPlacementComponent',
  });

  const filteredPatientsToBePlaced = useMemo(() => {
    let filteredPatientesToBePlaced = [...patientsToBePlaced];
    if (search) {
      filteredPatientesToBePlaced = filterBySearch({
        search,
        list: patientsToBePlaced,
        keys: ['visit.firstName', 'visit.lastName', 'visit.legalName', 'visit.legalFirstName'],
        minScore: 0.5,
      });
    }

    return filteredPatientesToBePlaced;
  }, [search, patientsToBePlaced]);

  const minDate = useMemo(() => startOfDay(new Date()), []);

  useEffect(() => {
    if (active && active.id && over?.id !== 'patientPlacementComponent') {
      setHiddentCardId(active.id as string);
    } else if (!isActionInProgress) {
      setHiddentCardId(undefined);
    }
  }, [over, active, isActionInProgress]);

  const isNextDayDisabled = false;

  const isPreviousDayDisabled = useMemo(() => {
    return dateRange.start <= minDate;
  }, [dateRange, minDate]);

  const handleNextDay = () => {
    onDateRangeChange({ start: addDays(dateRange.start, 1), end: addDays(dateRange.end, 1) });
  };

  const handlePreviousDay = () => {
    onDateRangeChange({ start: subDays(dateRange.start, 1), end: subDays(dateRange.end, 1) });
  };

  const handleDateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDatePickerClose = () => {
    setAnchorEl(null);
  };

  const datePickerOpen = Boolean(anchorEl);

  const handleDatePickerChange = (value: Date | null) => {
    if (value && isValid(value)) {
      onDateRangeChange({ start: startOfDay(value), end: endOfDay(value) });
      handleDatePickerClose();
    }
  };

  const navigation = useRef(useNavigate());

  const handlePatientCardClick = useCallback((id: string) => {
    navigation.current(`accommodation/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  return (
    <>
      <Stack
        padding="24px 24px 16px 24px"
        gap={4}
        position="sticky"
        zIndex={1}
        top={PATIENT_PLACEMENT_TOP_PLUS_MOVEMENT_REQUEST_CONTAINER_TOP_HEIGHT}
        bottom={0}
        bgcolor="white"
        boxShadow={shadows[2]}
        ref={setNodeRef}
        onClick={onHeaderClick}
        sx={{ cursor: 'pointer' }}
      >
        <Stack direction="row">
          <NoBedIcon color="secondary" />
          <Typography marginLeft={2} fontSize={16} fontWeight={600}>
            {i18n.toPlace}
          </Typography>
          {!loading && (
            <Typography fontSize={16} fontWeight={600} color={palette.secondary.main}>
              &nbsp;({filteredPatientsToBePlaced.length})
            </Typography>
          )}
        </Stack>

        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
          <IconButton onClick={handlePreviousDay} disabled={isPreviousDayDisabled} edge="start">
            <ArrowLeftIcon />
          </IconButton>

          <Stack spacing={1} alignItems="center">
            <Stack direction="row" gap={2} alignItems="center">
              <CalendarIcon color={datePickerOpen ? 'primary' : 'secondary'} />
              <Link
                component="button"
                onClick={(event) => handleDateClick(event)}
                color={datePickerOpen ? 'primary' : 'secondary'}
              >
                <Typography variant="body2" fontWeight={600} color="inherit">
                  {getDateText(dateRange.start)}
                </Typography>
              </Link>
            </Stack>
          </Stack>

          <IconButton onClick={handleNextDay} disabled={isNextDayDisabled} edge="end">
            <ArrowRightIcon />
          </IconButton>
        </Stack>
      </Stack>

      <PatientPlacementList
        hiddenCardId={hiddenCardId}
        loading={loading}
        patientsToBePlaced={filteredPatientsToBePlaced}
        isReadOnly={isReadOnly}
        onCardClick={handlePatientCardClick}
      />

      <Popover
        open={datePickerOpen}
        onClose={handleDatePickerClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ horizontal: 0, vertical: -8 }}
      >
        <DateCalendar minDate={minDate} onChange={(value) => handleDatePickerChange(value)} value={dateRange.start} />
      </Popover>
    </>
  );
};

const getDateText = (date: Date) => {
  let text = '';
  if (isToday(date)) {
    text += i18n.today + ', ' + i18n.the.toLowerCase();
  } else if (isTomorrow(date)) {
    text += i18n.tomorrow + ', ' + i18n.the.toLowerCase();
  } else {
    text += i18n.the;
  }
  const day = format(date, date.getDate() === 1 ? 'do' : 'dd', { locale: fr });
  const month = format(date, 'MMMM', { locale: fr });
  text += ` ${day} ${month}`;

  if (!isThisYear(date)) {
    text += ' ' + date.getFullYear();
  }

  return text;
};

export default PatientPlacementComponent;
