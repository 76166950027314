import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  cardHeaderClasses,
} from '@mui/material';
import { Draggable } from 'common/components';
import CommentIcon from 'common/components/Icons/Comment';
import EntryDashedIcon from 'common/components/Icons/EntryDashed';
import MoreVerticalIcon from 'common/components/Icons/MoreVertical';
import UnitIcon from 'common/components/Icons/Unit';
import { i18n } from 'common/locale';
import { formatDateTime } from 'common/utils';
import { AccommodationSpecificityBadges } from 'kurt/components';
import { Visit } from 'kurt/pages/Movements/useVisits';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import AccommodationCardMenu from '../AccommodationCardMenu';
import AccommodationHeaderTitle from '../AccommodationHeaderTitle';
import getColumnActions, { AccommodationAction } from '../VisitActions/columnActions';
import { VisitDetails } from '../VisitDetails';
import CardHeaderIcon from './CardHeaderIcon';
import CardSummary from './CardSummary';
import CardSummaryPatient from './CardSummaryPatient';
import { DataItem, DataItemList } from './DataItem';

type KanbanCardProps = {
  visit: Visit;
  isVisitActive?: boolean;
  isReadOnly?: boolean;
  onClick?: (id: string, type: 'accommodation' | 'mutation') => void;
};

//flow === admission
//status === requested
const KanbanCardAdmissionRequest: React.FC<KanbanCardProps> = ({
  visit,
  isVisitActive = false,
  isReadOnly = false,
  onClick,
}) => {
  const [over, setOver] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [menuActionName, setMenuActionName] = useState<AccommodationAction>();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const handleCloseConfirmDialog = () => {
    setMenuActionName(undefined);
    setConfirmDialogOpen(false);
  };

  const handleMenuItemClick = (actionName: AccommodationAction) => {
    toggleMenu();
    setMenuActionName(actionName);
    setConfirmDialogOpen(true);
  };

  const handleClick = () => {
    onClick && visit.basedOn && onClick(visit.basedOn.id, 'mutation');
  };

  const requestType = visit.basedOn?.requestType;
  const actions = getColumnActions({
    flow: 'admission',
    type: 'mutation',
    status: 'requested',
    requestType: requestType,
  });
  const mutationReason = visit.basedOn?.reason || visit?.nextAccommodation?.basedOn?.reason || '';
  const hasSpecificities = !!visit.basedOn?.specificities && visit.basedOn.specificities.length > 0;

  return (
    <>
      <Draggable id={visit.id} data={{ status: 'requested', data: visit }} disabled={isReadOnly || menuOpen}>
        <Card
          onMouseEnter={() => setOver(true)}
          onMouseLeave={() => setOver(false)}
          elevation={over ? 5 : 2}
          style={{ cursor: isVisitActive ? undefined : 'pointer' }}
          onClick={handleClick}
        >
          <CardHeader
            avatar={
              requestType !== 'DIRECT_ADMISSION' ? (
                <CardHeaderIcon Icon={UnitIcon} color="info" hasBorder />
              ) : (
                <CardHeaderIcon Icon={EntryDashedIcon} color="tertiary" hasBorder />
              )
            }
            title={
              <AccommodationHeaderTitle
                direction="column"
                from={
                  visit.basedOn?.directAdmissionOrigin || visit.previousAccommodation?.unit.name || i18n.defaultOrigin
                }
                fromBed={visit.previousAccommodation?.bed?.name}
                to={visit.unit.name || i18n.defaultOrigin}
                status="requested"
              ></AccommodationHeaderTitle>
            }
            action={
              <Stack direction="row" spacing={1} alignItems="center">
                {visit.basedOn?.comment && (
                  <Tooltip
                    title={
                      <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
                        {visit.comment}
                      </Typography>
                    }
                    placement="top"
                  >
                    <TooltipIconContainer container margin={(actions.length === 0 && '4px 8px 0 0') || '0'}>
                      <CommentIcon color="secondary" />
                    </TooltipIconContainer>
                  </Tooltip>
                )}
                {!isReadOnly && actions.length > 0 && (
                  <IconButton
                    ref={menuButtonRef}
                    onMouseDown={(event) => event.stopPropagation()}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleMenu();
                    }}
                  >
                    <MoreVerticalIcon />
                  </IconButton>
                )}
              </Stack>
            }
            sx={isReadOnly ? { [`& .${cardHeaderClasses.action}`]: { margin: '0 !important' } } : undefined}
          />

          <CardContent sx={{ paddingTop: 3 }}>
            <Stack spacing={3}>
              <CardSummary>
                <CardSummaryPatient
                  fullName={visit.patient.fullName}
                  birthdate={visit.patient.birthdate}
                  gender={visit.patient.gender}
                />
              </CardSummary>
              <DataItemList>
                <DataItem
                  label={visit.isMutationRequest ? i18n.receivedAt : i18n.createdAt}
                  value={formatDateTime(visit.basedOn?.createdAt)}
                  minWidth={100}
                />
                <DataItem label={i18n.mutation.reason} value={mutationReason} />
                {hasSpecificities && (
                  <Box flex={1}>
                    <AccommodationSpecificityBadges specificities={visit.basedOn?.specificities} />
                  </Box>
                )}
              </DataItemList>
            </Stack>
          </CardContent>
        </Card>
      </Draggable>
      <AccommodationCardMenu
        open={menuOpen}
        anchorEl={menuButtonRef.current}
        onClose={toggleMenu}
        actions={actions}
        onActionClick={handleMenuItemClick}
        visitId={visit.patient.id}
      />
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog} maxWidth="sm" scroll="body">
        <VisitDetails
          onClose={handleCloseConfirmDialog}
          visit={visit}
          flow="admission"
          status="requested"
          menuActionName={menuActionName}
        />
      </Dialog>
    </>
  );
};

const TooltipIconContainer = styled(Grid)`
  cursor: pointer;
  justify-content: flex-end;
  width: auto;
`;

export default KanbanCardAdmissionRequest;
