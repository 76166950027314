import Api, {
  AccommodationSpecificity,
  AccommodationStatus,
  Accommodation as ParseAccommodation,
  Patient,
  PatientGender,
  PerformerRequest,
  Practitioner,
  RequestStatus,
  RequestType,
  ThesaurusItem,
  Unit,
  UnitType,
} from '@ambuliz/sabri-core';
import { formatFirstname, formatName } from 'common/utils';
import { liveQueryClient } from 'core/live-query-client';
import { differenceInMilliseconds, endOfDay, isPast, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';

const OUTDATED_DATE_WARNING_THRESHOLD = 1000 * 60 * 60 * 2; // 2 hours;

export type Accommodation = {
  id: string;
  status: AccommodationStatus;
  pan: string;
  specificities: AccommodationSpecificity[];
  createdAt?: Date;
  admissionDate?: Date;
  dischargeDate?: Date;
  isDischargeDateValidated?: boolean;
  unit: {
    id: string;
    name: string;
    type?: UnitType;
  };
  responsibleUnit?: Pick<Unit, 'id' | 'name'>;
  bed?: {
    id: string;
    name: string;
  };
  comment?: string;
  isMutationRequest?: boolean;
  basedOn?: {
    id: string;
    reason?: string;
    status: RequestStatus;
    performerRequests?: PerformerRequest[];
    specificities?: AccommodationSpecificity[];
    comment?: string;
    visit?: Patient;
    createdAt: Date;
    directAdmissionOrigin?: string;
    requestType?: RequestType;
    thesaurusItem?: ThesaurusItem;
  };
  patient: {
    id: string;
    ipp: string;
    ins?: string;
    name: string;
    fullName: string;
    gender: PatientGender;
    birthdate: Date;
    isDischarged?: boolean;
  };
  previousAccommodation?: Accommodation;
  nextAccommodation?: Accommodation;
  isRejected?: boolean;
  numberOfRejections?: number;
  isAdmissionDateOutdated?: boolean;
  showAdmissionDateWarning?: boolean;
  isDischargeDateOutdated?: boolean;
  showDischargeDateWarning?: boolean;
  practitioners?: Practitioner[];
  thesaurusItem?: ThesaurusItem;
};

export const mapAccommodation = (
  accommodation: ParseAccommodation,
  previousAccommodation?: ParseAccommodation,
  nextAccommodation?: ParseAccommodation
): Accommodation | undefined => {
  if (!!accommodation.visit) {
    const name = `${accommodation.visit?.lastName?.toUpperCase()} ${formatFirstname(accommodation.visit?.firstName)}`;
    const fullName = formatName(
      accommodation.visit?.firstName,
      accommodation.visit?.lastName,
      accommodation.visit?.legalName,
      accommodation.visit?.legalFirstName
    );

    const isAdmissionDateOutdated =
      !!accommodation.visit &&
      !!accommodation.startAt &&
      isPast(accommodation.startAt) &&
      !['IN_PROGRESS', 'COMPLETED'].includes(accommodation.status);

    const isDischargeDateOutdated =
      !!accommodation.visit &&
      !!accommodation.endAt &&
      isPast(accommodation.endAt) &&
      accommodation.status !== 'COMPLETED';

    const mappedAccommodation: Accommodation = {
      id: accommodation.id,
      status: accommodation.status,
      pan: accommodation.visit.pan,
      specificities: accommodation.specificities || [],
      createdAt: accommodation.createdAt,
      admissionDate: accommodation.startAt as Date,
      dischargeDate: accommodation.endAt,
      isDischargeDateValidated: !accommodation.isEstimatedEnd,
      unit: {
        id: accommodation.unit.id,
        name: accommodation.unit.name,
        type: accommodation.unit.type,
      },
      responsibleUnit: accommodation.responsibleUnit,
      bed: accommodation.bed ? { id: accommodation.bed.id, name: accommodation.bed.name } : undefined,
      patient: {
        id: accommodation.visit.id,
        ipp: accommodation.visit.ipp,
        ins: accommodation.visit.ins,
        name,
        fullName,
        gender: accommodation.visit.gender,
        birthdate: accommodation.visit.birthdate,
        isDischarged: accommodation.visit.isDischarged,
      },
      comment: accommodation.comment,
      basedOn: accommodation.basedOn,
      previousAccommodation:
        previousAccommodation && previousAccommodation.visit ? mapAccommodation(previousAccommodation) : undefined,
      nextAccommodation: nextAccommodation && nextAccommodation.visit ? mapAccommodation(nextAccommodation) : undefined,
      practitioners: accommodation.practitioners,
      thesaurusItem: accommodation.thesaurusItem,
    };

    if (isAdmissionDateOutdated) {
      mappedAccommodation.isAdmissionDateOutdated = true;
      mappedAccommodation.showAdmissionDateWarning =
        differenceInMilliseconds(new Date(), accommodation.startAt as Date) > OUTDATED_DATE_WARNING_THRESHOLD;
    }
    if (isDischargeDateOutdated) {
      mappedAccommodation.isDischargeDateOutdated = true;
      mappedAccommodation.showDischargeDateWarning =
        differenceInMilliseconds(new Date(), accommodation.endAt as Date) > OUTDATED_DATE_WARNING_THRESHOLD;
    }

    return mappedAccommodation;
  }
  return undefined;
};

const useMovementAccommodations = ({ unitId, start, end }: { unitId: string; start?: Date; end?: Date }) => {
  const [accommodations, setAccommodations] = useState<Accommodation[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let liveSubscription: Parse.LiveQuerySubscription;

    const fetchAccommodations = async () => {
      const accommodations = await getAccommodations({ unitId, start, end });
      setAccommodations(accommodations);
    };

    const fetch = async () => {
      setLoading(true);

      liveSubscription = liveQueryClient.subscribe(
        new Api.Query(ParseAccommodation)
          .equalTo('unit', Unit.createWithoutData(unitId))
          .notEqualTo('status', 'CANCELLED'),
        Parse.User.current()?.getSessionToken()
      );

      liveSubscription.on('open', async () => {
        await fetchAccommodations();
        setLoading(false);
      });

      liveSubscription.on('create', fetchAccommodations);
      liveSubscription.on('enter', fetchAccommodations);
      liveSubscription.on('leave', fetchAccommodations);
      liveSubscription.on('update', fetchAccommodations);
    };

    fetch();

    return () => liveQueryClient.unsubscribe(liveSubscription);
  }, [unitId, start, end]);

  return {
    loading,
    accommodations,
  };
};

const getAccommodations = async ({ unitId, start, end }: { unitId: string; start?: Date; end?: Date }) => {
  const accommodations: Accommodation[] = [];

  // Admissions
  if (!!start) {
    const parseAccommodations = await new Api.Query(ParseAccommodation)
      .equalTo('unit', Unit.createWithoutData(unitId))
      .greaterThanOrEqualTo('startAt', startOfDay(start))
      .lessThanOrEqualTo('startAt', endOfDay(start))
      .notEqualTo('status', 'CANCELLED')
      .exists('visit')
      .include('visit', 'unit', 'bed', 'basedOn', 'basedOn.performerRequests.performer', 'responsibleUnit.name')
      .findAll();

    for (const parseAccommodation of parseAccommodations) {
      const previousAccommodation = parseAccommodation.basedOn
        ? await findPreviousAccommodation(parseAccommodation)
        : undefined;

      const mappedAccommodation = mapAccommodation(parseAccommodation, previousAccommodation);
      if (mappedAccommodation) {
        accommodations.push(mappedAccommodation);
      }
    }
    // Discharges
  } else if (!!end) {
    const parseAccommodations = await new Api.Query(ParseAccommodation)
      .exists('visit')
      .equalTo('unit', Unit.createWithoutData(unitId))
      .notEqualTo('status', 'CANCELLED')
      .greaterThanOrEqualTo('endAt', startOfDay(end))
      .lessThanOrEqualTo('endAt', endOfDay(end))
      .include('visit', 'unit', 'bed', 'basedOn')
      .findAll();

    for (const parseAccommodation of parseAccommodations) {
      const nextAccommodation = await findNextAccommodation(parseAccommodation);
      const mappedAccommodation = mapAccommodation(parseAccommodation, undefined, nextAccommodation);
      if (mappedAccommodation) {
        accommodations.push(mappedAccommodation);
      }
    }
  }

  return accommodations;
};

const findPreviousAccommodation = (accommodation: ParseAccommodation) => {
  return new Api.Query(ParseAccommodation)
    .notEqualTo('status', 'CANCELLED')
    .lessThan('startAt', accommodation.startAt)
    .equalTo('visit', accommodation.visit)
    .descending('startAt')
    .include('unit', 'visit', 'basedOn', 'bed')
    .first();
};
const findNextAccommodation = (accommodation: ParseAccommodation) => {
  return new Api.Query(ParseAccommodation)
    .notEqualTo('status', 'CANCELLED')
    .greaterThan('startAt', accommodation.startAt)
    .equalTo('visit', accommodation.visit)
    .ascending('startAt')
    .exists('basedOn')
    .include(
      'unit',
      'visit',
      'basedOn',
      'bed',
      'basedOn.performerRequests.performer',
      'basedOn.performerRequests.createdBy',
      'basedOn.performerRequests.fulfilledBy'
    )
    .first();
};

export default useMovementAccommodations;
