import { Accommodation, AccommodationRequest, Area, Fare, HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';
import { Dialog, SvgIconProps, Typography } from '@mui/material';

import { ConfirmDialog, Emoji } from 'common/components';
import CalendarIcon from 'common/components/Icons/Calendar';
import Emergency from 'common/components/Icons/Emergency';
import EntryDashedIcon from 'common/components/Icons/EntryDashed';
import UnitIcon from 'common/components/Icons/Unit';
import useParseQuery from 'common/hooks/useParseQuery';
import { i18n } from 'common/locale';
import { theme } from 'common/theme';
import { toast } from 'common/toast';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import DetailsDialogActionForm from './DetailsDialogActionForm';
import DetailsDialogContent from './DetailsDialogContent';
import SkeletonDetails from './Skeleton/SkeletonDetails';
import { DialogContext, DialogContextProvider } from './context/DialogContext';
import { Step, StepType } from './context/Step';

const DetailsDialog = () => {
  const { id, type } = useStepFromUrl();
  const { locationId, visitId, stepType, loading } = useStepResources({ id, type });
  const navigate = useNavigate();

  if (!loading && !locationId && !visitId) {
    toast.error(i18n.detailsDialog.common.unknownStep);
    navigate('..');
    return null;
  }
  return (
    <DialogContextProvider id={id} type={stepType} locationId={locationId} visitId={visitId}>
      <DetailsDialogContainer type={!loading ? stepType : type} isLoading={loading} />
    </DialogContextProvider>
  );
};

const DetailsDialogContainer = ({ type, isLoading }: { type?: StepType; isLoading?: boolean }) => {
  const {
    isLoading: isDataLoading,
    editModeLoading,
    action,
    initialStep,
    visit,
    currentStep,
    cancelAction,
    isDirty,
    submitEditableStep,
  } = useContext(DialogContext);

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [confirmLeaveOpen, setConfirmLeaveOpen] = useState(false);

  const handleClose = (forceClose?: boolean) => {
    if (!forceClose && isDirty()) {
      setConfirmLeaveOpen(true);
    } else {
      setOpen(false);
      setConfirmLeaveOpen(false);
      navigate('..');
    }
  };

  const loading = isLoading || isDataLoading;

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty()) {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (!initialStep && !loading) {
      navigate('..');
    }
  }, [initialStep, loading, navigate]);

  if (action && currentStep) {
    return (
      <DetailsDialogActionForm
        action={action}
        step={
          action === 'DISCHARGE_PATIENT' && currentStep.type === 'mutation' && initialStep ? initialStep : currentStep
        }
        visit={visit}
        onCancel={cancelAction}
        onSuccess={cancelAction}
        onClose={() => {
          cancelAction();
          handleClose();
        }}
      />
    );
  }

  return (
    <>
      <Dialog
        open={open && !confirmLeaveOpen}
        onClose={() => handleClose()}
        maxWidth={loading ? undefined : 'lg'}
        fullWidth={!loading}
        scroll="body"
        PaperProps={{
          sx: {
            maxWidth: `${theme.breakpoints.values.md}px !important`,
          },
        }}
      >
        {loading ? (
          <SkeletonDetails type={type} onClose={() => handleClose()} />
        ) : (
          <DetailsDialogContent onClose={() => handleClose()} />
        )}
      </Dialog>
      <ConfirmDialog
        open={confirmLeaveOpen}
        title={i18n.leaveWithoutSaving.title}
        icon={<Emoji name="thinkingFace" />}
        confirmLabel={i18n.saveAlt}
        loading={loading || editModeLoading}
        onBack={() => setConfirmLeaveOpen(false)}
        onClose={() => handleClose(true)}
        onConfirm={async () => {
          await submitEditableStep();
          handleClose(true);
        }}
      >
        <Typography color="textSecondary">{i18n.leaveWithoutSaving.content}</Typography>
      </ConfirmDialog>
    </>
  );
};

const getStepClass = (type: StepType) => {
  switch (type) {
    case 'accommodation':
      return Accommodation;

    case 'mutation':
      return AccommodationRequest;

    case 'healthCareEnvironmentalCleaning':
      return HealthcareEnvironmentalCleaning;

    case 'closedBed':
      return Accommodation;

    case 'fare':
      return Fare;

    case 'availableBed':
      return Area;

    default:
      return Accommodation;
  }
};

const useStepFromUrl = () => {
  const match = useMatch('*');

  const id = match?.params?.['*']?.split('/').pop()!;
  let type = match?.params?.['*']?.split('/').slice(-2)[0] || 'accommodation';

  if (type === 'hospitalizations') {
    type = 'mutation';
  }

  if (type === 'accommodations') {
    type = 'accommodation';
  }

  if (type === 'cleaning') {
    type = 'healthCareEnvironmentalCleaning';
  }

  if (type === 'available-bed') {
    type = 'availableBed';
  }

  return {
    id,
    type: type as StepType,
  };
};

const useStepResources = ({ id, type }: { id: string; type: StepType }) => {
  const { results, isLoading } = useParseQuery<Step>(
    new Parse.Query(getStepClass(type)).select('location', 'visit', 'bed').equalTo('objectId', id),
    {
      enableLiveQuery: false,
    }
  );

  const { locationId, visitId, stepType } = useMemo(() => {
    const step = results?.[0];
    const stepType = getStepType(step);

    let locationId: string | undefined;
    let visitId: string | undefined;

    switch (stepType) {
      case 'accommodation':
        visitId = (step as Accommodation)?.visit?.id;
        break;

      case 'mutation':
        visitId = (step as AccommodationRequest)?.visit?.id;
        break;

      case 'healthCareEnvironmentalCleaning':
        locationId = (step as HealthcareEnvironmentalCleaning)?.location?.id;
        break;

      case 'closedBed':
        locationId = (step as Accommodation).bed?.id;
        break;

      case 'availableBed':
        locationId = (step as Area).id;
    }

    return {
      locationId,
      visitId,
      stepType,
    };
  }, [results]);

  return {
    locationId,
    visitId,
    stepType,
    loading: isLoading,
  };
};

export const getAccommodationIcon = (
  isDirectAdmission: boolean,
  isMutation: boolean,
  isUnitEmergency?: boolean
): { icon: React.FC<SvgIconProps>; color: 'success' | 'info' | 'tertiary' | 'danger' } => {
  if (isDirectAdmission) {
    return { icon: EntryDashedIcon, color: 'tertiary' };
  }
  if (isMutation) {
    if (isUnitEmergency) {
      return { icon: Emergency, color: 'danger' };
    }
    return { icon: UnitIcon, color: 'info' };
  }
  return { icon: CalendarIcon, color: 'success' };
};

export function getStepType(step: Step): StepType {
  if (step instanceof Accommodation) {
    return step.visit ? 'accommodation' : 'closedBed';
  } else if (step instanceof AccommodationRequest) {
    return 'mutation';
  } else if (step instanceof HealthcareEnvironmentalCleaning) {
    return 'healthCareEnvironmentalCleaning';
  } else if (step instanceof Fare) {
    return 'fare';
  } else if (step instanceof Area) {
    return 'availableBed';
  }

  return 'accommodation';
}

export default DetailsDialog;
