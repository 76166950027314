import { SvgIcon, SvgIconProps } from '@mui/material';

const FullChevronLeftIcon: React.FC<SvgIconProps> = ({ ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5303 14.5303C11.8232 14.2374 11.8232 13.7626 11.5303 13.4697L6.06062 8L11.5303 2.53033C11.8232 2.23744 11.8232 1.76257 11.5303 1.46967C11.2374 1.17678 10.7625 1.17678 10.4696 1.46967L4.64641 7.2929C4.25589 7.68342 4.25588 8.31659 4.64641 8.70711L10.4696 14.5303C10.7625 14.8232 11.2374 14.8232 11.5303 14.5303Z"
    />
  </SvgIcon>
);

export default FullChevronLeftIcon;
