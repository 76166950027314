import Api, { Accommodation, Anomaly, Patient, Unit, UnitType } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { endOfDay, startOfDay } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

type UsePatientPlacementParams = {
  unitIds: string[];
  enabled: boolean;
};

const usePatientPlacement = ({ unitIds, enabled }: UsePatientPlacementParams) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange>({ start: startOfDay(new Date()), end: endOfDay(new Date()) });

  const { results: plannedAccommodations, isLoading: isPlannedAccommodationsLoading } = useParseQuery(
    plannedAccommodationsQuery({ unitIds, ...dateRange }),
    { enabled }
  );

  const secondaryQueriesEnabled = useMemo(
    () => enabled && !isPlannedAccommodationsLoading && plannedAccommodations.length > 0,
    [enabled, plannedAccommodations.length, isPlannedAccommodationsLoading]
  );

  const { results: originAccommodations, isLoading: isOriginAccommodationsLoading } = useParseQuery(
    originAccommodationsQuery({
      unitIds,
      visitIds: plannedAccommodations.map((accommodation) => accommodation.visit!.id),
    }),
    { enabled: secondaryQueriesEnabled }
  );

  const { results: anomalies, isLoading: isAnomaliesLoading } = useParseQuery(
    anomaliesQuery({
      unitIds,
      accommodationIds: plannedAccommodations.map((accommodation) => accommodation.id),
    }),
    { enabled: secondaryQueriesEnabled }
  );

  const patientsToBePlaced = useMemo(() => {
    const patientsToBePlaced: PatientToBePlaced[] = [];

    for (const plannedAccommodation of plannedAccommodations) {
      const accommodation: PatientToBePlaced = plannedAccommodation;
      for (const originAccommodation of originAccommodations) {
        if (accommodation.basedOn && accommodation.visit!.id === originAccommodation.visit!.id) {
          accommodation.fromUnit = {
            id: originAccommodation.unit.id,
            name: originAccommodation.unit.name,
            type: originAccommodation.unit.type,
          };
          accommodation.fromBed = originAccommodation.bed
            ? { id: originAccommodation.bed.id, name: originAccommodation.bed.name }
            : undefined;
        }
      }

      for (const anomaly of anomalies) {
        if (accommodation.id === anomaly.accommodation!.id) {
          accommodation.startOutdated = true;
        }
      }
      patientsToBePlaced.push(accommodation);
    }

    return patientsToBePlaced.sort((a, b) => a.startAt.getTime() - b.startAt.getTime());
  }, [plannedAccommodations, originAccommodations, anomalies]);

  useEffect(() => {
    if (enabled) {
      if (!isPlannedAccommodationsLoading) {
        if (
          plannedAccommodations.length === 0 ||
          (secondaryQueriesEnabled && !isAnomaliesLoading && !isOriginAccommodationsLoading)
        ) {
          setIsLoading(false);
        }
      }
    }
  }, [
    enabled,
    secondaryQueriesEnabled,
    isPlannedAccommodationsLoading,
    plannedAccommodations.length,
    isAnomaliesLoading,
    isOriginAccommodationsLoading,
  ]);

  useEffect(() => {
    if (enabled && isPlannedAccommodationsLoading) {
      setIsLoading(true);
    }
  }, [dateRange, isPlannedAccommodationsLoading, enabled]);

  return {
    loading: isLoading,
    patientsToBePlaced,
    dateRange,
    onDateRangeChange: setDateRange,
  };
};

type PatientToBePlaced = Accommodation & {
  fromUnit?: { id: string; name: string; type: UnitType };
  fromBed?: { id: string; name: string };
  startOutdated?: boolean;
};
type DateRange = {
  start: Date;
  end: Date;
};

export type { DateRange, PatientToBePlaced };

const plannedAccommodationsQuery = ({ unitIds, start, end }: { unitIds: string[]; start: Date; end: Date }) =>
  new Api.Query(Accommodation)
    .equalTo('status', 'PLANNED')
    .greaterThanOrEqualTo('startAt', start)
    .lessThanOrEqualTo('startAt', end)
    .containedIn(
      'unit',
      unitIds.map((id) => Unit.createWithoutData(id))
    )
    .doesNotExist('bed')
    .exists('visit')
    .include('visit', 'basedOn', 'basedOn.visit');

const originAccommodationsQuery = ({ unitIds, visitIds }: { unitIds: string[]; visitIds: string[] }) =>
  new Api.Query(Accommodation)
    .notEqualTo('status', 'CANCELLED')
    .notContainedIn(
      'unit',
      unitIds.map((id) => Unit.createWithoutData(id))
    )
    .containedIn(
      'visit',
      visitIds.map((id) => Patient.createWithoutData(id))
    )
    .include('visit', 'unit', 'bed');

const anomaliesQuery = ({ unitIds, accommodationIds }: { unitIds: string[]; accommodationIds: string[] }) =>
  new Api.Query(Anomaly)
    .containedIn(
      'unit',
      unitIds.map((id) => Unit.createWithoutData(id))
    )
    .containedIn(
      'objectId',
      accommodationIds.map((id) => Accommodation.createWithoutData(id))
    )
    .equalTo('status', 'OPEN')
    .equalTo('type', 'OUTDATED_START')
    .exists('accommodation');

export default usePatientPlacement;
