import { Box, Stack } from '@mui/material';
import { color, palette, transition } from 'common/theme';
import { getEventCardHeight } from 'kurt/components/EventCard/EventCard';
import React, { Children, ReactElement, ReactNode, useContext } from 'react';
import styled from 'styled-components';
import TimelineAddButton from './TimelineAddButton';
import { TimelineBackgroundVariant } from './TimelineBackground';
import TimelineContext, { Period } from './TimelineContext';
import TimelineGroupedRowItem, { TIMELINE_GROUP_WIDTH } from './TimelineGroupedRowItem';
import { TimelineRowItemProps } from './TimelineRowItem';

export const TIMELINE_ROW_TITLE_MARGIN_RIGHT = 8;
const OVERLAP_SPACING = 20;
const TIMELINE_ROW_MIN_HEIGHT = 48;
const TIMELINE_ROW_TITLE_PADDING = 8;

type TimelineRowProps = {
  title?: ReactNode;
  children?: ReactNode;
  onButtonClick?: (start: Date) => void;
  selected?: boolean;
  isFirstRow?: boolean;
  isLastRow?: boolean;
  isOver?: boolean;
  hasDivider?: boolean;
};

const TimelineRowTitle = styled(Stack)<{
  variant: TimelineBackgroundVariant;
  $rowHeaderWidth: number;
  $hasDivider: boolean;
}>`
  background-color: ${palette.background.default};
  position: sticky;
  width: ${({ $rowHeaderWidth }) => $rowHeaderWidth}px;
  transition: height ${transition};
  will-change: height;
  padding-left: ${({ variant }) => (variant === 'card' ? 8 : 5)}px;
  left: 0;
  z-index: 5;
  margin-right: ${TIMELINE_ROW_TITLE_MARGIN_RIGHT}px;
  ${({ $hasDivider }) =>
    $hasDivider
      ? `
  &:after {
    content: "''";
    width: 120px;
    height: 1px;
    bottom: 0;
    right: 0;
    position: absolute;
    background-color: ${color.grey[20]};
  }
 `
      : undefined};
`;

const TimelineRowContainer = styled.div<{ variant: TimelineBackgroundVariant; $selectedRow?: boolean }>`
  display: flex;
  position: relative;
  min-height: ${TIMELINE_ROW_MIN_HEIGHT}px;
  ${({ variant, $selectedRow }) =>
    variant === 'card'
      ? `&:hover > ${TimelineRowTitle} > div {
        background-color: ${color.blue[10]};
      }
      &:hover > div:last-child {
        background-color: ${color.blue[10]}80;
        box-shadow: -1px 0px 0px 0px ${color.grey[10]}, -8px 0px 0px 0px ${color.blue[10]};
      }`
      : $selectedRow &&
        `background-color: white;
        ${TimelineRowTitle} > div {
          background-color: white;
        }
        `}
`;

const getGroupedInterval = (scale: number) => {
  let divider = 2;
  while (scale / divider > 40) {
    divider += 2;
  }
  return scale / divider;
};

const TimelineRow = ({
  title,
  children,
  onButtonClick,
  selected = false,
  isFirstRow,
  isLastRow,
  isOver,
  hasDivider = false,
}: TimelineRowProps) => {
  const {
    start: timelineStart,
    end: timelineEnd,
    period,
    scale,
    rowHeaderWidth,
    variant,
  } = useContext(TimelineContext);
  const now = new Date();

  const containerRef = React.useRef<HTMLDivElement>(null);
  let items: ReactElement[] = [];
  const groupedItems: Record<string, any> = {};
  const groupedInterval = getGroupedInterval(scale);
  const factor = getTimelineFactor(period);
  const childrenArray = Children.toArray(children) as ReactElement[];

  for (const child of childrenArray) {
    if (child.props.start && child.props.start < timelineEnd) {
      const start = new Date(child.props.start);
      const end = child.props.end ? new Date(child.props.end) : timelineEnd;

      let eventWidth = Math.floor((scale * (end.getTime() - start.getTime())) / factor);
      let eventLeft = Math.floor((scale * (start.getTime() - timelineStart.getTime())) / factor);
      let fillWidth = eventWidth;
      let fillLeft = eventLeft;

      if (end.getTime() === timelineEnd.getTime()) {
        eventWidth = eventWidth + 4;
        fillWidth = fillWidth + 4;
      }
      if (eventWidth <= groupedInterval) {
        const intervalId = Math.floor(Math.floor((eventLeft + eventWidth) / groupedInterval) * groupedInterval);
        const children = groupedItems[intervalId]?.children || [];

        children.push(
          React.cloneElement(child, {
            ...child.props,
            registerSticky: false,
          })
        );

        groupedItems[intervalId] = {
          id: (groupedItems[intervalId]?.id || '') + child.key,
          children,
          left: intervalId + 4,
          width: TIMELINE_GROUP_WIDTH,
          top: 0,
          emoji: child.props.emoji,
          color: child.props.color,
          striped: child.props.striped,
        };
      } else {
        if (child.props.isEndOutdated) {
          fillWidth = Math.floor((scale * (now.getTime() - start.getTime())) / factor);
        }
        if (child.props.isStartOutdated) {
          eventLeft = Math.floor((scale * (now.getTime() - timelineStart.getTime())) / factor);
          eventWidth = Math.max(Math.floor((scale * (end.getTime() - now.getTime())) / factor), 42);

          if (end < now) {
            fillWidth = Math.floor((scale * (now.getTime() - start.getTime())) / factor) + eventWidth;
          }
        }

        items.push(
          React.cloneElement(child, {
            ...child.props,
            top: 0,
            left: eventLeft,
            width: eventWidth,
            fillWidth,
            fillLeft,
          })
        );
      }
    }
  }

  for (let { id, left, width, children, top, emoji, color, striped } of Object.values(groupedItems)) {
    const overflowPosition = left;
    let fillWidth = 0;

    if (children.length === 1) {
      const nowPosition = Math.floor((scale * (now.getTime() - timelineStart.getTime())) / factor);

      if (children[0].props.isStartOutdated) {
        fillWidth = nowPosition - left + width;
        left += fillWidth - width;
      } else if (children[0].props.isEndOutdated) {
        fillWidth = nowPosition - left;
      }
    }
    items.push(
      <TimelineGroupedRowItem
        key={id}
        left={left}
        width={width}
        fillWidth={fillWidth}
        fillLeft={overflowPosition}
        top={top}
        title={title}
        emoji={emoji}
        color={color}
        striped={striped}
        onMouseEnter={(ids) =>
          children
            .find((child: ReactElement<TimelineRowItemProps>) => child.props.onMouseEnter)
            ?.props.onMouseEnter(ids)
        }
        onMouseLeave={() =>
          children.find((child: ReactElement<TimelineRowItemProps>) => child.props.onMouseLeave)?.props.onMouseLeave()
        }
        {...(children.length === 1 ? children[0].props : null)}
      >
        {children}
      </TimelineGroupedRowItem>
    );
  }

  items = items.sort((a, b) => (a.props.fillLeft || a.props.left) - (b.props.fillLeft || b.props.left));
  const rowSteps: ReactElement[][] = [];

  items.forEach((item) => {
    const itemLeft = item.props.fillLeft || item.props.left;

    for (let i = 0; i < rowSteps.length; i++) {
      const isOverlapping = rowSteps[i].some((rowItem) => {
        const rowItemLeft = rowItem.props.fillLeft || rowItem.props.left;
        const rowItemWidth = rowItem.props.fillWidth || rowItem.props.width;

        return itemLeft <= rowItemLeft + rowItemWidth && itemLeft >= rowItemLeft;
      });

      if (!isOverlapping) {
        rowSteps[i].push(React.cloneElement(item, { ...item.props, top: i * OVERLAP_SPACING }));
        return;
      }
    }
    rowSteps.push([React.cloneElement(item, { ...item.props, top: rowSteps.length * OVERLAP_SPACING })]);
  });
  items = rowSteps.flat();

  const rowHeight =
    rowSteps.length > 0
      ? (rowSteps.length - 1) * OVERLAP_SPACING +
        rowSteps[rowSteps.length - 1].reduce(
          (heightOffset, item) => Math.max(heightOffset, getEventCardHeight(item.props.size)),
          0
        ) +
        TIMELINE_ROW_TITLE_PADDING * 2
      : 48;

  // Hack to hide timeline background on top of the first row and bottom of the last row
  const topBoxShadow = isFirstRow ? `0px -5px ${palette.background.default}` : undefined;
  const bottomBoxShadow = isLastRow ? `0px 8px ${palette.background.default}` : undefined;
  const boxShadow = [topBoxShadow, bottomBoxShadow].filter((s) => s).join(',');

  // Drag And Drop lovely border <3

  const topBoxBorder = isFirstRow
    ? {
        borderTop: `1px dashed ${color.blue[40]}`,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      }
    : undefined;
  const bottomBoxBorder = isLastRow
    ? {
        borderBottom: `1px dashed ${color.blue[40]}`,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      }
    : undefined;
  const overBorder = isOver
    ? {
        borderLeft: `1px dashed ${color.blue[40]}`,
        borderRight: `1px dashed ${color.blue[40]}`,
        ...topBoxBorder,
        ...bottomBoxBorder,
      }
    : undefined;

  return (
    <TimelineRowContainer variant={variant} $selectedRow={selected}>
      {title && (
        <TimelineRowTitle
          style={{
            height: rowHeight,
            boxShadow,
          }}
          flexShrink={0}
          variant={variant}
          $rowHeaderWidth={rowHeaderWidth}
          $hasDivider={hasDivider}
        >
          <Box display="flex" height="100%" borderRadius="8px 0 0 8px" alignItems="center" justifyContent="center">
            {title}
          </Box>
        </TimelineRowTitle>
      )}
      <Box position="relative" display="flex" width="100%" ref={containerRef}>
        <Box
          width="100%"
          paddingY={`${TIMELINE_ROW_TITLE_PADDING}px`}
          sx={{
            overflowX: 'hidden',
            borderBottom: hasDivider ? `1px dashed ${color.grey[20]}` : undefined,
            ...overBorder,
          }}
        >
          {onButtonClick && <TimelineAddButton containerRef={containerRef} onClick={onButtonClick} />}
          {items.sort((a, b) => a.props.top - b.props.top)}
        </Box>
      </Box>
    </TimelineRowContainer>
  );
};

export const getTimelineFactor = (period: Period) => 1000 * 60 * 60 * (period === 'day' ? 1 : 24);

export default TimelineRow;
