import Api, { Practitioner, Unit } from '@ambuliz/sabri-core';
import { useCallback, useEffect, useState } from 'react';

export type PractitionerQueryParams = {
  unitIds: string[];
};

type UsePractitionersProps = PractitionerQueryParams & {
  enabled?: boolean;
};

const usePractitioners = ({ unitIds, enabled }: UsePractitionersProps) => {
  const [practitioners, setPractitioners] = useState<Practitioner[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPractitioners = useCallback(async () => {
    setLoading(true);

    try {
      const practitioners = await getPractitionersQuery({ unitIds }).ascending('lastName').find();
      setPractitioners(practitioners);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [unitIds]);

  useEffect(() => {
    if (enabled) {
      fetchPractitioners();
    }
  }, [fetchPractitioners, enabled]);

  return { practitioners, loading };
};

const getPractitionersQuery = ({ unitIds }: PractitionerQueryParams) =>
  new Api.Query(Practitioner).containedIn(
    'unit',
    unitIds.map((id) => Unit.createWithoutData(id))
  );

export default usePractitioners;
