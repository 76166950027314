"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VISIT_TYPES = exports.TYPES = exports.APPOINTMENT_MESSAGE_TYPES = void 0;
const APPOINTMENT_MESSAGE_TYPES = ['CREATE_APPOINTMENT', 'UPDATE_APPOINTMENT'];
exports.APPOINTMENT_MESSAGE_TYPES = APPOINTMENT_MESSAGE_TYPES;
const VISIT_TYPES = [
    'ADMIT_PATIENT',
    'CANCEL_ADMIT_PATIENT',
    'DISCHARGE_PATIENT',
    'CANCEL_DISCHARGE_PATIENT',
    'TRANSFER_PATIENT',
    'CANCEL_TRANSFER_PATIENT',
    'PRE_ADMIT_PATIENT',
    'CANCEL_PRE_ADMIT_PATIENT',
    'CHANGE_OUTPATIENT_TO_INPATIENT',
    'PENDING_DISCHARGE_PATIENT',
    'UPDATE_RESPONSIBLE_UNIT',
    'CHANGE_INPATIENT_TO_OUTPATIENT',
    'RETURN_FROM_LEAVE_OF_ABSENCE',
];
exports.VISIT_TYPES = VISIT_TYPES;
const TYPES = [
    ...VISIT_TYPES,
    ...APPOINTMENT_MESSAGE_TYPES,
    'CREATE_PATIENT',
    'UPDATE_PATIENT',
    'MERGE_PATIENT',
];
exports.TYPES = TYPES;
