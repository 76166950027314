import { SvgIcon, SvgIconProps } from '@mui/material';

export const BellOutlinedIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24946 7.38734C4.24985 7.4 4.25004 7.41267 4.25004 7.42533V10.6667C4.25004 11.0594 4.14942 11.4286 3.97255 11.75H12.0275C11.8507 11.4286 11.75 11.0594 11.75 10.6667V7.42533C11.75 7.41267 11.7502 7.4 11.7506 7.38734C11.8114 5.38797 10.405 3.64299 8.4384 3.27764C8.27871 3.24797 8.13084 3.18873 8.00004 3.10622C7.86924 3.18873 7.72138 3.24797 7.56168 3.27764C5.59503 3.64299 4.18867 5.38797 4.24946 7.38734ZM9.39221 15.25C9.09409 15.7019 8.58188 16 8.00005 16C7.41821 16 6.90601 15.7019 6.60788 15.25L6.60514 15.2458C6.57091 15.1936 6.53953 15.1393 6.51122 15.0833L6.50938 15.0796C6.39677 14.8551 6.33338 14.6016 6.33338 14.3333C6.33331 14.3055 6.33399 14.2777 6.33541 14.25C6.33716 14.2159 6.34004 14.1819 6.34405 14.148C6.35353 14.0638 6.42466 14.0001 6.50938 14H9.49071C9.57566 13.9995 9.64714 14.0635 9.65605 14.148C9.66005 14.1819 9.66293 14.2159 9.66468 14.25C9.66611 14.2777 9.66678 14.3055 9.66671 14.3333C9.66671 14.5969 9.60553 14.8462 9.4966 15.0677C9.49465 15.0717 9.49269 15.0757 9.49071 15.0796C9.46195 15.1369 9.42997 15.1924 9.39501 15.2457L9.39221 15.25ZM7.33337 2.04867C4.76043 2.52665 2.9205 4.80958 3.00004 7.42533V10.6667C3.00004 11.219 2.55233 11.6667 2.00004 11.6667C1.63185 11.6667 1.33337 11.9651 1.33337 12.3333C1.33337 12.7015 1.63185 13 2.00004 13H14C14.3682 13 14.6667 12.7015 14.6667 12.3333C14.6667 11.9651 14.3682 11.6667 14 11.6667C13.4478 11.6667 13 11.219 13 10.6667V7.42533C13.0796 4.80958 11.2397 2.52665 8.66671 2.04867V0.666667C8.66671 0.298477 8.36823 0 8.00004 0C7.63185 0 7.33337 0.298477 7.33337 0.666667V2.04867ZM8.41293 14.279L8.41447 14.2928C8.41393 14.2882 8.41342 14.2836 8.41293 14.279ZM8.41468 14.2946L8.41447 14.2928L8.41468 14.2946Z"
        fill="#666D8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.79657 3.26102C7.23094 3.30064 6.66494 3.52654 6.4023 3.68237L5.76444 2.60737C6.21006 2.34296 6.96787 2.066 7.70923 2.01407C8.45783 1.96164 9.38059 2.11285 10.179 2.58495L9.54274 3.66091C9.01113 3.34656 8.35497 3.2219 7.79657 3.26102Z"
        fill="#666D8E"
      />
    </SvgIcon>
  );
};
