import Api, { ACCESSIBLE_ROLES, Cloud, HealthCenter, User, UserRole } from '@ambuliz/sabri-core';
import AuthenticatedUser from 'core/authentication/types/User';
import { useMemo } from 'react';
import { Pagination } from '../hooks/usePagination';
import { UserCreateForm, UserUpdateForm } from './UserManagement.model';

export const useBuildUserQuery = (
  pagination: Pagination,
  connectedUser: AuthenticatedUser,
  name: string,
  roles: UserRole[],
  healthCenterId: string,
  disabled: boolean
) => {
  const stringifiedRoles = JSON.stringify(roles);
  const stringifiedPagination = JSON.stringify(pagination);

  const query = useMemo(() => {
    const roles = JSON.parse(stringifiedRoles);
    const pagination = JSON.parse(stringifiedPagination);

    const usersByUsernameQuery = new Api.Query(User).matches('username', new RegExp(name), 'i');
    const usersByFirstNameQuery = new Api.Query(User).matches('porter.firstName', new RegExp(name), 'i');
    const usersByLastNameQuery = new Api.Query(User).matches('porter.lastName', new RegExp(name), 'i');

    const query = name
      ? Parse.Query.or(usersByUsernameQuery, usersByFirstNameQuery, usersByLastNameQuery)
      : new Parse.Query(User);

    if (disabled) {
      query.equalTo('disabled', true);
    } else {
      query.equalTo('disabled', false);
    }

    if (healthCenterId) {
      query.equalTo('healthCenters', HealthCenter.createWithoutData(healthCenterId));
    }
    query
      .include('porter', 'unit')
      .limit(pagination.pageSize)
      .skip(pagination.currentPageIndex * pagination.pageSize);

    const emptyRolesFilter = roles.length === 0;
    if (emptyRolesFilter) {
      query.containedIn('role', ACCESSIBLE_ROLES[connectedUser.role]);
    } else {
      query.containedIn('role', roles);
    }

    return query;
  }, [stringifiedRoles, stringifiedPagination, connectedUser.role, name, healthCenterId, disabled]);

  return query;
};

export const createUser = async (
  user: UserCreateForm & {
    unit?: string;
    lastName?: string;
    firstName?: string;
  }
) => {
  if (user.healthCenterId) {
    try {
      const newUserResult = await Cloud.createUser({ ...user, unitId: user.unit });
      return newUserResult.user;
    } catch (err) {
      throw new Error(err.message);
    }
  }
};

export const updateUser = async (
  user: UserUpdateForm & {
    unit?: string;
    lastName?: string;
    firstName?: string;
  }
) => {
  if (user.objectId) {
    try {
      const updatedUserResult = await Cloud.updateUser({
        unitId: user.unit,
        lastName: user.lastName,
        firstName: user.firstName,
        id: user.objectId,
        healthCenterId: user.healthCenterId,
        notificationConfigurations: user.notificationConfigurations,
        navigationACL: user.navigationACL,
        units: user.units,
        isReadOnly: user.isReadOnly,
        whitelistedIPs: user.whitelistedIPs,
        disabled: user.disabled,
        disabledReason: user.disabledReason,
      });
      return updatedUserResult.user;
    } catch (err) {
      throw new Error(err.message);
    }
  }
};
