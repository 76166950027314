import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import CommentIcon from 'common/components/Icons/Comment';
import MoreVerticalIcon from 'common/components/Icons/MoreVertical';
import { i18n } from 'common/locale';
import shadows from 'common/theme/shadows';
import { format, isToday } from 'date-fns';
import AccommodationSpecificityBadges from 'kurt/components/AccommodationSpecificityBadges';
import { getAccommodationIcon } from 'kurt/components/DetailsDialog/DetailsDialog';
import AccommodationCardMenu from 'kurt/components/PatientFlowKanban/AccommodationCardMenu';
import CardHeaderIcon from 'kurt/components/PatientFlowKanban/KanbanCard/CardHeaderIcon';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import getColumnActions from 'kurt/components/PatientFlowKanban/VisitActions/columnActions';
import { VisitDetails } from 'kurt/components/PatientFlowKanban/VisitDetails';
import PatientCard from 'kurt/pages/Movements/MovementSheets/Cards/PatientCard';
import { Visit } from 'kurt/pages/Movements/useVisits';
import { useRef, useState } from 'react';

export type MovementRequestCardProps = {
  visit: Visit;
  onClick: (movementId: string) => void;
  isReadOnly?: boolean;
};

export const MovementRequestCard = ({ visit, onClick, isReadOnly }: MovementRequestCardProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuActionName, setMenuActionName] = useState<AccommodationAction>();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const actions = getColumnActions({
    flow: 'admission',
    type: 'mutation',
    status: 'requested',
    requestType: visit.basedOn?.requestType,
  });

  const handleCloseConfirmDialog = () => {
    setMenuActionName(undefined);
    setConfirmDialogOpen(false);
  };

  const handleMenuItemClick = (actionName: AccommodationAction) => {
    toggleMenu();
    setMenuActionName(actionName);
    setConfirmDialogOpen(true);
  };

  if (!visit.basedOn || !visit.patient) {
    return;
  }

  const { patient, basedOn: request } = visit;

  const cardHeaderIcon = getAccommodationIcon(
    !!request.directAdmissionOrigin,
    request.requestType !== 'DIRECT_ADMISSION',
    visit.previousAccommodation?.unit.type === 'EMERGENCY'
  );

  const dateAsText = isToday(request.createdAt)
    ? i18n.receivedAtHour + ' ' + format(request.createdAt, 'HH:mm')
    : i18n.receivedAt + ' ' + format(request.createdAt, 'dd/MM');

  const from = visit.previousAccommodation?.unit.name || request.directAdmissionOrigin;

  return (
    <>
      <Card
        onClick={() => onClick(request.id)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            boxShadow: shadows[4],
          },
        }}
      >
        <CardHeader
          avatar={<CardHeaderIcon Icon={cardHeaderIcon.icon} color={cardHeaderIcon.color} hasBorder />}
          title={
            <Stack direction="row" gap={1} alignItems="center">
              <EllipsisTypography variant="body2" color="secondary">
                {from}
              </EllipsisTypography>
              {visit.bed && <Chip label={visit.bed} variant="rounded" size="x-small" />}
            </Stack>
          }
          action={
            <Stack direction="row" alignItems="center" gap={2}>
              {visit.comment && (
                <Tooltip title={visit.comment}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CommentIcon color="secondary" />
                  </Box>
                </Tooltip>
              )}
              {!isReadOnly && (
                <IconButton
                  ref={menuButtonRef}
                  onMouseDown={(event) => event.stopPropagation()}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleMenu();
                  }}
                >
                  <MoreVerticalIcon />
                </IconButton>
              )}
            </Stack>
          }
        />
        <CardContent>
          <Stack spacing={3}>
            <PatientCard
              birthdate={patient.birthdate}
              gender={patient.gender}
              name={patient.fullName}
              specificities={request.specificities}
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={4}>
              <Stack direction="row" gap={1} alignItems="center" minWidth={100}>
                <Typography variant="body2" color="secondary" fontWeight={600}>
                  {dateAsText}
                </Typography>
              </Stack>
              {request.reason && (
                <EllipsisTypography variant="body2" color="secondary" fontWeight={600} flex={1}>
                  {request.reason}
                </EllipsisTypography>
              )}

              <AccommodationSpecificityBadges specificities={request.specificities} />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <AccommodationCardMenu
        open={menuOpen}
        anchorEl={menuButtonRef.current}
        onClose={toggleMenu}
        actions={actions}
        onActionClick={handleMenuItemClick}
        visitId={visit.patient.id}
      />
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog} maxWidth="sm" scroll="body">
        <VisitDetails
          onClose={handleCloseConfirmDialog}
          visit={visit}
          flow="admission"
          status="requested"
          menuActionName={menuActionName}
        />
      </Dialog>
    </>
  );
};
